<footer class="footer">
  <!-- Start Footer Top -->
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-12" style="padding-left:0px;">
          <!-- Single Widget -->
          <div class="single-footer f-about">
            <div class="logo">
              <a class="navbar-brand-logo" [href]="environment.rUrl">
                <img src="assets/images/l4.png" alt="Logo" style="position: relative;left: -15px;" />
              </a>
            </div>
            <p style="margin-left: 29px;">Making business operations easier</p>
            <p style="line-height: 0px;margin-left: 29px;">through simplified SAAS solutions.</p>
            <ul class="social" style="margin-left: 24px;">
              <li>
                <a href="javascript:void(0)"><i class="lni lni-facebook-filled"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="lni lni-twitter-original"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="lni lni-instagram"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="lni lni-linkedin-original"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="lni lni-youtube"></i></a>
              </li>
            </ul>
          </div>
          <!-- End Single Widget -->
        </div>
        <div class="col-lg-8 col-md-8 col-12">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-12">
              <!-- Single Widget -->
              <div class="single-footer f-link">
                <h3>Programs</h3>
                <ul>
                  <li><a [href]="environment.rUrl + 'startup'">Startup</a></li>
                  <li>
                    <a [href]="environment.rUrl + 'essentials'">Essentials</a>
                  </li>
                  <li><a [href]="environment.rUrl + 'splash'">Splash</a></li>
                  <li>
                    <a [href]="environment.rUrl + 'all-in-one'">All In One</a>
                  </li>
                  <li>
                    <a [href]="environment.rUrl + 'non-profit'">Non-Profit</a>
                  </li>
                  <li>
                    <a [href]="environment.rUrl + 'ngo'">Non-Governmental Organization</a>
                  </li>
                </ul>
              </div>
              <!-- End Single Widget -->
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <!-- Single Widget -->
              <div class="single-footer f-link">
                <h3>Information</h3>
                <ul>
                  <li><a [href]="environment.rUrl + 'about-us'">About Us</a></li>
                  <li>
                    <a [href]="environment.rUrl + 'how-it-works'">How it Works</a>
                  </li>
                  <li><a [href]="environment.rUrl + 'blog'">Blog</a></li>
                  <li>
                    <a [href]="environment.rUrl + 'huge-savings'">Huge Savings</a>
                  </li>
                </ul>
              </div>
              <!-- End Single Widget -->
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <!-- Single Widget -->
              <div class="single-footer f-link">
                <h3>Help & Policies</h3>
                <ul>
                  <li><a [href]="environment.rUrl + 'faq'">FAQ</a></li>
                  <li>
                    <a [href]="environment.rUrl + 'pricing-faq'">Pricing FAQ</a>
                  </li>
                  <li>
                    <a [href]="environment.list + '/signup'">Register Now</a>
                  </li>
                  <li>
                    <a [href]="environment.rUrl + 'terms-of-use'">Terms of Use</a>
                  </li>
                  <li>
                    <a [href]="environment.rUrl + 'disclaimer'">Disclaimer</a>
                  </li>
                  <li>
                    <a [href]="environment.rUrl + 'privacy-policy'">Privacy Policy</a>
                  </li>
                  <li>
                    <a [href]="environment.rUrl + 'cancellation-policy'">Cancellation Policy</a>
                  </li>
                  <li>
                    <a [href]="environment.rUrl + 'refund-policy'">Refund Policy</a>
                  </li>
                </ul>
              </div>
              <!-- End Single Widget -->
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <!-- Single Widget -->
              <div class="single-footer f-link">
                <h3>Contact</h3>
                <ul>
                  <li><a class="contact">Phone</a></li>
                  <li><a class="contact">91-xxxxx xxxxx</a></li>
                  <li><a class="contact">Email</a></li>
                  <li><a class="contact">info&#64;orcollate.com</a></li>
                  <li>
                    <a [href]="environment.rUrl + 'contact-us'">Contact Us</a>
                  </li>
                  <li>
                    <a [href]="environment.rUrl + 'partners'">Partner With Us</a>
                  </li>
                  <li><a [href]="environment.rUrl + 'support'">Support</a></li>
                </ul>
              </div>
              <!-- End Single Widget -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ End Footer Top -->
  <div class="copyright-area">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="copyright-text">
            Copyright © 2024 Orcollate. All Rights Resevered.
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>