import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import {
  ApolloClientOptions,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { HttpClientModule, HttpHeaders } from "@angular/common/http";
import { onError } from "@apollo/client/link/error";
import { environment } from "../environments/environment";

const uri =  environment.apisbe;

const authMiddleware = new ApolloLink((operation: any, forward: any) => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  });
  return forward(operation);
});
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // need help on linking this with graphql module
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) =>
        console.log(extensions["exception"])
      );
    }
    if (networkError) {
      console.log("networkError", networkError);
      console.log(
        `[GraphQL Network error]: ${networkError.name}, message: ${networkError.message}, stack: ${networkError.stack}`
      );
    }
  });
  const link = ApolloLink.from([errorLink, httpLink.create({ uri })]);
  const cache = new InMemoryCache();
  return {
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        errorPolicy: "all",
      },
    },
  };
}
@NgModule({
  exports: [ApolloModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
