import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { User_Lists } from '../graphql/graphql.queries';

@Injectable({
  providedIn: 'root'
})
export class RpricingnoService {

  constructor(private apollo: Apollo) { }
  

  rpricingno(api_key: string,no: string) {
    return this.apollo.watchQuery({
      query: gql`
      query rpricingno($no: String!) {
        rpricingno(no: $no) {
          id
          appid
          pname
          pdesc
          ests
          ptype
          count
          country
          omp
          oyp
        }
      }
    `,
    fetchPolicy: 'network-only',
      variables: {
        no: no
      },
    })
  }



}


