<style>
  .cpUrcL {
    background-color: rgb(255, 255, 255);
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: visible;
    padding: 0px 40px;
  }

  .gyNNcP {
    font-size: 4.20875rem;
    line-height: 1.2;
    white-space: pre-line;
    text-align: left;
    color: #000;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .jHlQff {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: row;
    padding-top: 54px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .daVUaS {
    color: #f88542;
  }

  .iEImVO {
    width: 100%;
    line-height: 1.71429;
    height: auto;
    padding-bottom: 32px;
  }

  .bnrLqb > p {
    line-height: 1.4;
  }

  .geygRN > form button {
    background-color: rgb(0, 107, 255);
  }

  .jTIkMI {
    height: 64px;
    margin: 0px;
    width: max-content;
  }

  .jhMgBW {
    transition: all 0.2s linear 0s;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    gap: 8px;
    font-family: Gilroy;
    font-weight: 600;
    min-height: 49px;
    height: auto;
    margin: 8px 0px;
    border-style: solid;
    border-width: 2px;
    border-radius: 8px;
    border-color: transparent;
    background-color: rgb(0, 0, 0);
    padding: 10px 18px;
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    font-size: 1.125rem;
    line-height: 1.6;
  }

  button {
    border: none;
    margin: 0px;
    padding: 0px;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    text-align: inherit;
    outline: none;
    appearance: none;
  }

  .bg1 {
    background-color: #fff;
  }

  .animate {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.4s ease 0s, transform 0.4s ease 0s;
  }

  .kkowYa {
    font-size: 1.75rem;
    line-height: 1.6;
    text-align: center;
    color: #333;
    font-weight: 700;
    margin-bottom: 72px;
    max-width: 50%;
  }

  .cli {
    height: 44px;
  }

  .cli img {
    max-height: 100%;
  }

  .pad1 {
    padding-top: 72px;
  }

  .pad2 {
    padding: 100px 0px 100px 0px;
  }

  .about-h2 {
    font-size: 3.125rem;
    line-height: 1.2;
    text-align: center;
    color: #333;
    font-weight: 700;
    margin-bottom: 0px;
    max-width: 770px;
    z-index: 10;
  }

  .abt-sep1 ol {
    list-style: none;
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
  }

  .fYUilb {
    position: relative;
    padding-bottom: 16px;
    text-align: left;
  }

  .iptWaS {
    display: flex;
    align-items: flex-start;
    width: auto;
  }

  .c2 {
    max-width: 1100px;
  }

  .fYUilb {
    position: relative;
    padding-bottom: 16px;
    text-align: left;
  }

  .iptWaS {
    display: flex;
    align-items: flex-start;
    width: auto;
  }

  .elCVvG {
    height: 100%;
    width: auto;
    margin-right: 12px;
    margin-top: 4px;
  }

  .fvJbjT {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .fYUilb p {
    line-height: 1.4;
    padding-bottom: 0px;
  }

  .hqRGcy {
    text-align: left;
    color: #333;
    font-size: 1.55rem;
    line-height: 1.4;
    font-weight: 700;
    margin-left: 4px;
    letter-spacing: 0.5px;
  }

  .eVbhjr {
    width: 100%;
    height: 100%;
    margin-left: 4px;
  }

  .fYUilb p {
    line-height: 1.4;
    padding-bottom: 0px;
    font-size: 16px;
  }

  .iEImVO {
    width: 100%;
    line-height: 1.71429;
    height: auto;
    padding-bottom: 32px;
  }

  .abt-sep1 i {
    font-size: 22px;
    color: #f88542;
  }

  .abtc p {
    line-height: 1.7;
    padding-bottom: 0px;
    font-size: 16px;
  }

  .abtc {
    line-height: 1.7;
    padding-bottom: 0px;
    font-size: 16px;
  }

  .example-h2 {
    margin: 10px;
  }

  .example-section {
    display: flex;
    align-content: center;
    align-items: center;
    height: 60px;
  }

  .example-margin {
    margin: 10px;
  }
</style>
<style>
  .sticky-right {
    position: sticky;
    top: 0;
    height: 100vh;
    /* Adjust the height as needed */
    overflow-y: auto;
    /* Enable scrolling if the content exceeds the height */
  }

  .sticky-content {
    height: 200px;
    /* Adjust the height of the right-side content */
    width: 100%;
  }

  .top10 {
    padding: 15px 0px 5px 0px !important;
    border-bottom: 1px solid #e5e7eb;
  }

  .top11 {
    padding: 0px 0px 5px 0px !important;
  }

  .prig {
    padding-right: 10%;
    line-height: 40px;
  }

  .justify-between {
    justify-content: space-between;
  }

  .flex {
    display: flex;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  .font-semibold {
    font-weight: bold;
  }

  .ck {
    padding: 10px;
    background: #ff5722;
    text-align: center;
    border-radius: 9999px;
    margin-top: 20px;
  }

  .ck a {
    display: block;
    text-decoration: none;
    color: #fff;
  }

  .fors {
    width: auto;
    display: inline-block;
    min-width: 120px;
  }

  .no {
    display: inline-block;
    width: auto;
    max-width: 130px;
  }

  .bl {
    border-bottom: 1px solid #dddada;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .mt {
    margin-top: 10px;
  }

  .crt {
    width: 40px;
    height: 40px;
    background: #ff5722;
    font-size: 24px;
    color: #fff;
    border-radius: 50%;
  }

  .crt i {
    position: relative;
    top: 4px;
    left: 6px;
  }

  .inc {
    position: relative;
    left: 5px;
  }

  .dec {
    position: relative;
    right: 5px;
  }

  .odd {
    background-color: #ffffff;
  }

  .even {
    background-color: #fffff0;
  }

  .m1 {
    padding-right: 10px;
    font-size: 22px;
    font-weight: bold;
    position: relative;
    top: -5px;
  }

  .m2 {
    padding-left: 10px;
    font-size: 22px;
    font-weight: bold;
    position: relative;
    top: -5px;
  }

  .m3 {
    position: relative;
    top: 0px;
  }

  .sw {
    display: initial;
    background: #f5f0f0;
    border-radius: 50px;
    width: 280px;
    padding: 10px;
    color: #333;
    margin: auto;
    letter-spacing: 0.7px;
    margin-top: 140px;
  }

  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #f3efef;
  }

  .ra {
    text-align: center;
    position: relative;
    top: -15px;
    background: #fff;
    height: 60px;
    line-height: 60px;
    color: #62dd62;
  }
</style>
<div class="auth-container">
  <!-- loader.component.html -->

  <app-home-header></app-home-header>
  <div class="bg1">
    <div class="container c2">
      <div class="row text-center">
        <div class="sw form-check form-switch">
          <span class="m1">Monthly </span> <span class="m1"></span>
          <span class="m3">
            <input
              style="
                margin-left: 4.9em;
                font-size: 26px;
                margin-top: -31px;
                border: 0px;
              "
              mdbCheckbox
              class="form-check-input"
              type="checkbox"
              (change)="handleToggleChange($event)"
              [checked]="false"
            />
          </span>
          <span class="m2">Yearly</span>
        </div>

        <!-- <mat-slide-toggle [(ngModel)]="slideToggleValue"
                            (change)="handleToggleChange($event)"></mat-slide-toggle> -->
      </div>
      <div class="row" style="padding-top: 50px">
        <div class="col-sm-12 col-md-8" style="border-right: 1px solid #eee2e2">
          <!--row start-->
          <div
            class="row bl"
            *ngFor="let appl of datavalue; let i = index"
            [ngClass]="{ odd: i % 2 === 0, even: i % 2 !== 0 }"
          >
          <!-- *ngIf="i === 1 && param1" -->
            <!-- <h2 class="ra" *ngIf="i === 1 && appl.catname == 2">
              Higly Recommended
            </h2>
            <h2 class="ra" style="background: #fbfbfb;" *ngIf="appl.catname == 1  && appl.id!=param1">
              Recommended Applications
            </h2>
            <h2 class="ra" style="background: #fbfbfb;" *ngIf="appl.catname == 3">
              Recommended Busistream
            </h2> -->

            <ng-container *ngIf="i === 0 || datavalue[i - 1].catname !== appl.catname">
              <!-- Print category name only if it's the first item or different from the previous category -->
              <h2 class="ra" style="background: #fbfbfb;" *ngIf="appl.catname == 2">
                Highly Recommended
              </h2>
              <h2 class="ra" style="background: #fbfbfb;" *ngIf="appl.catname == 1   && appl.id!=param1">
                Recommended Applications
              </h2>
              <h2 class="ra" style="background: #fbfbfb;" *ngIf="appl.catname == 3">
                Recommended Busistream
              </h2>
            </ng-container>
   
            <div class="col-sm-4 mplan1">
              <div class="row">
                <div class="col-sm-12">
                  <b></b>
                  <b
                    style="color: #f88542; display: block; padding-left: 10px"
                    >{{ appl.appname }}</b
                  >
                  <img
                    src="{{ appl.applogo }}"
                    style="width: 100px; padding-right: 10px"
                  />
                </div>
              </div>
            </div>

            <div class="col-sm-4 mplan2">
              <div class="row">
                <div class="mt">
                  <b>Plan</b>
                </div>
                <div class="col-sm-12">
                  <select
                    class="fors form-control"
                    [(ngModel)]="selectedPlanId[i]"
                    (change)="onPlanChange($event.target.value, i)"
                  >
                    <option
                      *ngFor="let plan of datavalue[i]?.appplan"
                      [value]="plan.id"
                    >
                      {{ plan.planname }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-4 mplan3">
              <div class="row">
                <div class="mt"><b>Qty</b></div>
                <div class="col-sm-12">
                  <button
                    (click)="decrement(i)"
                    class="dec"
                    [disabled]="ests[i] != 1"
                  >
                    <i class="fa fa-minus-circle" aria-hidden="true"></i>
                  </button>
                  <input
                    [disabled]="ests[i] != 1"
                    type="number"
                    [value]="
                      freecount[i] >= +datavalue[i]?.appplan[0].count
                        ? freecount[i]
                        : +datavalue[i]?.appplan[0].count
                    "
                    min="0"
                    class="form-control no"
                    (input)="onNumberChange($event.target.value, i)"
                  />
                  <button
                    (click)="increment(i)"
                    class="inc"
                    [disabled]="ests[i] != 1"
                  >
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <!-- Selected Plan: {{ selectedPlans[i] }} -->
              <div class="flex justify-between top11">
                <span>&nbsp;</span>
                <span
                  class="font-semibold text-slate-900 dark:text-slate-200 prig"
                >
                  <span>
                    <!-- Content to be displayed when datavalue1[0]?.omp is greater than 0 -->
                    {{ selectedPlans[i] | currency : ctype }}
                  </span>
                </span>
                <ng-template #defaultNumber>
                  <span
                    class="font-semibold text-slate-900 dark:text-slate-200 prig"
                  >
                    {{ defaultNumberValue }}
                  </span>
                </ng-template>
              </div>
            </div>
          </div>
          <!--row end-->
        </div>
        <div class="col-sm-12 col-md-4 mot1 sticky-right">
          <div class="sticky-content">
            <h3 class="text-lg font-semibold">Order Summary</h3>
            <div class="flex justify-between top10">
              <span>Order Total</span
              ><span class="font-semibold text-slate-900 dark:text-slate-200">{{
                calculateOverallTotal() | currency : ctype
              }}</span>
            </div>
            <div class="ck" style="padding: 0px;">
              <button (click)="goToCheckout()" style="width: 100%; color: #fff; font-size: 18px;padding: 10px;">Checkout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Busistream-->
</div>
<div class="mtot2">
  <div class="flex justify-between top10">
    <span>Order Total</span
    ><span class="font-semibold text-slate-900 dark:text-slate-200">{{
      calculateOverallTotal() | currency : ctype
    }}</span>
  </div>
  <div class="ck" style="padding: 0px !important;">
    <button (click)="goToCheckout()" style="width: 100%; color: #fff;font-size: 18px;padding: 10px;">Checkout</button>
  </div>
</div>
<app-home-footer></app-home-footer>
