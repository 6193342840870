import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class UpaymentpostService {
  userdatalist1: any[] = [];

  constructor(private apollo: Apollo) {}

  updatepayment(odid:string) {
    return this.apollo.mutate({
      mutation: gql`
        mutation upaymentpost(
          $odid: String!
        ) {
          upaymentpost(
            odid: $odid
           ) {
            code
            message
            invno
          }
        }
      `,
      variables: {
        odid: odid,
        
      },
    });
  }
}



