<style>
    .cpUrcL {
        background-color: rgb(255, 255, 255);
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        overflow: visible;
        padding: 0px 40px;
    }

    .gyNNcP {
        font-size: 4.20875rem;
        line-height: 1.2;
        white-space: pre-line;
        text-align: left;
        color: #000;
        font-weight: 700;
        margin-bottom: 32px;
    }

    .jHlQff {
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        flex-direction: row;
        padding-top: 54px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .daVUaS {
        color: #f88542;
    }

    .iEImVO {
        width: 100%;
        line-height: 1.71429;
        height: auto;
        padding-bottom: 32px;
    }

    .bnrLqb>p {
        line-height: 1.4;
    }

    .geygRN>form button {
        background-color: rgb(0, 107, 255);
    }

    .jTIkMI {
        height: 64px;
        margin: 0px;
        width: max-content;
    }

    .jhMgBW {
        transition: all 0.2s linear 0s;
        position: relative;
        display: inline-flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
        gap: 8px;
        font-family: Gilroy;
        font-weight: 600;
        min-height: 49px;
        height: auto;
        margin: 8px 0px;
        border-style: solid;
        border-width: 2px;
        border-radius: 8px;
        border-color: transparent;
        background-color: rgb(0, 0, 0);
        padding: 10px 18px;
        color: rgb(255, 255, 255);
        outline: none;
        cursor: pointer;
        font-size: 1.125rem;
        line-height: 1.6;
    }

    button {
        border: none;
        margin: 0px;
        padding: 0px;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        text-align: inherit;
        outline: none;
        appearance: none;
    }

    .bg1 {
        background-color: #fff;
    }

    .animate {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity 0.4s ease 0s, transform 0.4s ease 0s;
    }

    .kkowYa {
        font-size: 1.75rem;
        line-height: 1.6;
        text-align: center;
        color: #333;
        font-weight: 700;
        margin-bottom: 72px;
        max-width: 50%;
    }

    .cli {
        height: 44px;
    }

    .cli img {
        max-height: 100%;
    }

    .pad1 {
        padding-top: 72px;
    }

    .pad2 {
        padding: 100px 0px 100px 0px;
    }

    .about-h2 {
        font-size: 3.125rem;
        line-height: 1.2;
        text-align: center;
        color: #333;
        font-weight: 700;
        margin-bottom: 0px;
        max-width: 770px;
        z-index: 10;
    }

    .abt-sep1 ol {
        list-style: none;
        width: 100%;
        height: 100%;
        margin-bottom: 16px;
    }

    .fYUilb {
        position: relative;
        padding-bottom: 16px;
        text-align: left;
    }

    .iptWaS {
        display: flex;
        align-items: flex-start;
        width: auto;
    }

    .c2 {
        max-width: 1100px;
    }

    .fYUilb {
        position: relative;
        padding-bottom: 16px;
        text-align: left;
    }

    .iptWaS {
        display: flex;
        align-items: flex-start;
        width: auto;
    }

    .elCVvG {
        height: 100%;
        width: auto;
        margin-right: 12px;
        margin-top: 4px;
    }

    .fvJbjT {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .fYUilb p {
        line-height: 1.4;
        padding-bottom: 0px;
    }

    .hqRGcy {
        text-align: left;
        color: #333;
        font-size: 1.55rem;
        line-height: 1.4;
        font-weight: 700;
        margin-left: 4px;
        letter-spacing: 0.5px;
    }

    .eVbhjr {
        width: 100%;
        height: 100%;
        margin-left: 4px;
    }

    .fYUilb p {
        line-height: 1.4;
        padding-bottom: 0px;
        font-size: 16px;
    }

    .iEImVO {
        width: 100%;
        line-height: 1.71429;
        height: auto;
        padding-bottom: 32px;
    }

    .abt-sep1 i {
        font-size: 22px;
        color: #f88542;
    }

    .abtc p {
        line-height: 1.7;
        padding-bottom: 0px;
        font-size: 16px;
    }

    .abtc {
        line-height: 1.7;
        padding-bottom: 0px;
        font-size: 16px;
    }

    .cc {
        width: 50%;
        height: 44px;
        padding: 10px;
        float: left;
    }

    .cc1 {
        width: 100px;
        height: 44px;
        background: #00b5ed;
        text-align: center;
        float: left;
        font-weight: bold;
    }

    .loader {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        border-top-color: #000;
        animation: spin 1s infinite ease-in-out;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .ckbut {
        width: 60%;
        background: #f88542;
        height: 40px;
        border-radius: 20px;
        color: #ffff;
        font-weight: 500;
        font-size: 18px !important;
        text-align: center;
        margin: auto;
        display: block;
    }
</style>

<style>
    .sticky-right {
        position: sticky;
        top: 0;
        height: 100vh;
        /* Adjust the height as needed */
        overflow-y: auto;
        /* Enable scrolling if the content exceeds the height */
    }

    .sticky-content {
        height: 200px;
        /* Adjust the height of the right-side content */
        width: 100%;
    }

    .top10 {
        padding: 15px 0px 5px 0px !important;
        border-bottom: 1px solid #e5e7eb;
    }

    .top11 {
        padding: 0px 0px 5px 0px !important;
    }

    .prig {
        padding-right: 10%;
        line-height: 40px;
    }

    .justify-between {
        justify-content: space-between;
    }

    .flex {
        display: flex;
    }

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    .font-semibold {
        font-weight: bold;
    }

    .ck {
        padding: 10px;
        background: #f88542;
        text-align: center;
        border-radius: 9999px;
        margin-top: 20px;
        color: #fff;
        font-weight: bold;
    }

    .ck a {
        display: block;
        text-decoration: none;
        color: #fff;
    }

    .fors {
        width: auto;
        display: inline-block;
        min-width: 120px;
    }

    .no {
        display: inline-block;
        width: auto;
        max-width: 130px;
    }

    .bl {
        border: 1px solid #dddada;
        padding-bottom: 0px;
        padding-top: 15px;
        margin-top: 5px;
    }

    .mt {
        margin-top: 10px;
    }

    .crt {
        width: 40px;
        height: 40px;
        background: #ff5722;
        font-size: 24px;
        color: #fff;
        border-radius: 50%;
    }

    .crt i {
        position: relative;
        top: 4px;
        left: 6px;
    }

    .inc {
        position: relative;
        left: 5px;
    }

    .dec {
        position: relative;
        right: 5px;
    }

    .odd {
        background-color: #ffffff;
    }

    .even {
        background-color: #fffff0;
    }

    .m1 {
        padding-right: 10px;
        font-size: 22px;
        font-weight: bold;
    }

    .m2 {
        padding-left: 10px;
        font-size: 22px;
        font-weight: bold;
    }

    .m3 {
        position: relative;
        top: -2px;
    }

    .sw {
        display: initial;
        background: #f5f0f0;
        border-radius: 50px;
        width: 280px;
        padding: 10px;
        color: #333;
        margin: auto;
        letter-spacing: 0.7px;
        margin-top: 14px;
    }

    .form-control[disabled],
    .form-control[readonly],
    fieldset[disabled] .form-control {
        background-color: #f3efef;
    }

    .err {
        color: red;
        padding-top: 5px;
    }

    .suc {
        color: green;
        padding-top: 5px;
    }
</style>
<div class="auth-container">
    <app-home-header></app-home-header>
    <div class="bg1">
        <div class="container c2">
            <div class="row text-center">
                <h1>Order Summary</h1>
            </div>

            <div class="row" style="padding-top: 120px">
                <div class="col-sm-7">
                    <!--row start-->
                    <div style="padding-bottom: 10px;" class="row bl" *ngFor="let appl of datavalue; let i = index"
                        [ngClass]="{ odd: i % 2 === 0, even: i % 2 !== 0 }">
                        <div class="col-sm-4">
                            <div class="row">
                                <div class="col-sm-12" *ngFor="let appvalue of datavalue[i]?.appdata">
                                    <b style="color: #f88542; display: block; padding-left: 10px">{{
                                        appvalue.appname }}</b>
                                    <img src="{{
                                        appvalue.appimg }}" style="width: 100px; padding-right: 10px" />
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <!-- Selected Plan: {{ selectedPlans[i] }} -->
                            <div class="flex justify-between top11">
                                <span class="font-semibold text-slate-900 dark:text-slate-200 prig">Plan:</span>

                                <span class="text-slate-900 dark:text-slate-200 prig">
                                    <span *ngIf="appl.plantype == 1">
                                        Free
                                    </span>
                                    <span *ngIf="appl.plantype == 2">
                                        Custom
                                    </span>
                                    <span *ngIf="appl.plantype == 3">
                                        Micro
                                    </span>
                                    <span *ngIf="appl.plantype == 4">
                                        Small
                                    </span>
                                    <span *ngIf="appl.plantype == 5">
                                        Medium
                                    </span>
                                    <span *ngIf="appl.plantype == 6">
                                        Enterprises
                                    </span>
                                </span>

                            </div>
                            <div class="flex justify-between top11">
                                <span class="font-semibold text-slate-900 dark:text-slate-200 prig">Qty:</span>

                                <span class=" text-slate-900 dark:text-slate-200 prig">
                                    <span>
                                        <!-- Content to be displayed when datavalue1[0]?.omp is greater than 0 -->
                                        {{ appl.qty }}
                                    </span>
                                </span>

                            </div> 

                            <div class="flex justify-between top11">
                                <span class="font-semibold text-slate-900 dark:text-slate-200 prig">Unit Price:</span>

                                <span class="text-slate-900 dark:text-slate-200 prig">
                                    <span>
                                        <!-- Content to be displayed when datavalue1[0]?.omp is greater than 0 -->
                                        {{ +appl.price / +appl.qty | currency: ctype }}
                                    </span>
                                </span>

                            </div>

                        </div>


                        <div class="col-sm-4">
                            <div class="flex justify-between top11 ftot">
                                <span class="font-semibold text-slate-900 dark:text-slate-200 prig">&nbsp;</span>

                                <span style="background: #D8E8D4;color: #000;text-align: center;padding: 0px 24px;"
                                    class="font-semibold text-slate-900 dark:text-slate-200 prig">
                                    <span>
                                        <!-- Content to be displayed when datavalue1[0]?.omp is greater than 0 -->
                                        {{ appl.price | currency: ctype }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <!--row end-->
                </div>
                <div class="col-sm-5 sticky-right">
                    <div class="sticky-content">
                        <div class="flex justify-between top10">
                            <span>Order Total</span><span class="font-semibold text-slate-900 dark:text-slate-200">{{
                                cktot | currency: ctype }}</span>
                        </div>
                        <div class="flex justify-between top10" *ngIf="apidisamt"><span>Discount</span><span
                                class="font-semibold text-slate-900 dark:text-slate-200" style="color:green
                                ;">- {{
                                apidisamt | currency: ctype }}</span></div>
                        <div class="flex justify-between top10" *ngIf="distot"> 
                            <span>Order Total with Discount</span><span
                                class="font-semibold text-slate-900 dark:text-slate-200">{{
                                distot | currency: ctype }}</span>
                        </div>
                        <div class="flex justify-between top10">
                            <span>Tax estimate</span><span class="font-semibold text-slate-900 dark:text-slate-200">{{
                                cktax | currency: ctype }}</span>
                        </div>
                        <div class="flex justify-between top10">
                            <span>Order total</span><span class="font-semibold text-slate-900 dark:text-slate-200">{{
                                (cktot - apidisamt) + (+cktax) | currency: ctype }}</span>
                        </div>
                        <form [formGroup]="couponForm" style="margin-top: 20px;" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label>Have coupon?</label>
                                <div class="input-group">
                                    <input type="text" style="padding: 10px;" class="form-control cc coupon"
                                        formControlName="couponCode" value="{{ccode}}" placeholder="Coupon code">
                                    <button class="btn cc1 btn-primary btn-apply coupon" type="submit">Apply</button>
                                    <div style="clear:both;"></div>
                                    <div class="err">{{err}}</div>
                                    <div *ngIf="suc" class="suc">{{suc}} <span (click)="rmcoupon()"
                                            style="color:red;cursor:pointer;font-size: 20px;position: relative;top: 3px;"><i
                                                class="fa fa-times-circle" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div style="padding-top:40px;">
                            <button class="ckbut" (click)="handleClick()" [disabled]="isLoading">
                                <span *ngIf="!isLoading"><span *ngIf="this.ulevel==1">Proceed to Pay</span> <span
                                        *ngIf="this.ulevel==3">Confirm to Purchase</span></span>
                                <div *ngIf="isLoading" class="loader"></div>
                            </button>
                        </div>

                        <!-- <button (click)="proceed()">Pay With Razorpay</button> -->

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Busistream-->
</div>

<app-home-footer></app-home-footer>