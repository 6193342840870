import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class UpdateccService {
  userdatalist1: any[] = [];

  constructor(private apollo: Apollo) {}

  updateccode(odid:string, ccode:string, disamount:string) {
    return this.apollo.mutate({
      mutation: gql`
        mutation uorders(
          $odid: String!
          $ccode: String
          $disamount: String
        ) {
          uorders(
            odid: $odid
            ccode: $ccode
            disamount: $disamount
           ) {
            code
            message
          }
        }
      `,
      variables: {
        odid: odid,
        ccode: ccode,
        disamount: disamount,
        
      },
    });
  }
}

