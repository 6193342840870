import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import AOS from "aos";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { CartsesService } from "../services/cartses.service";
import { ReadcartService } from "../services/readcart.service";
import { VerifycouponService } from "../services/verifycoupon.service";
import { UpdateccService } from "../services/updatecc.service";
import { RordersService } from "../services/rorders.service";
import { AddCartService } from "../services/add-cart.service";
import { UpaymentService } from "../services/upayment.service";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { UpaymentpostService } from "../services/upaymentpost.service";
import { ExternalLibraryService } from "../util";
import { environment } from '../../environments/environment';

declare let Razorpay: any;

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  environment = environment;
  datavaluecc: any;
  datavalue: any;
  datavalue1: any[] = [];
  appcall: string;
  api_key: string;
  selectedParentOption: any;
  selectedChildOption: any;
  selectedPlanId: number[] = [];
  selectedind: any;
  appid: any;
  appPlans: string[] = [];
  price: any;
  planPrices: any = {};
  qty: any;
  ptype: any;
  selectedindex: number[] = [];
  selectedprice: number[] = [];
  duration: number;
  cartid: any;
  cktot: any;
  cktax: any;
  couponForm: FormGroup;
  err: any;
  disval: any;
  suc: string;
  perval: any;
  distot: any;
  ccode: any;
  dataccod: any;
  orderdata: any;
  apidisamt: any;
  rmc: any;
  sendcart: any[] = [];
  paydata: any;
  ulevel: any;
  ctype: string | null = null;
  country: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rcart: ReadcartService,
    private cartses: CartsesService,
    private formBuilder: FormBuilder,
    private verifycoupon: VerifycouponService,
    private updatecc: UpdateccService,
    private readorders: RordersService,
    private addtocard: AddCartService,
    private upayment: UpaymentService,
    private upaymentpost: UpaymentpostService,
    private razorpayService: ExternalLibraryService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.perval = 0;
  }
  currencies = [
    { value: "USD", viewValue: "United States Dollar" },
    { value: "INR", viewValue: "Indian Currency" },
    { value: "GBP", viewValue: "British Pound Sterling" },
  ];
  selectedCurrency: string;

  name = "Angular";
  response;
  razorpayResponse;
  showModal = false;

  readcart() {
    this.rcart.readcart(this.cartid).valueChanges.subscribe(
      ({ data }: any) => {
        this.datavalue = data.rcart;
        if(this.datavalue.length==0){
          this.router.navigate(['/cart']);
        } 
        this.cktot = this.datavalue.reduce(
          (sum, item) => sum + parseFloat(item.price),
          0
        );

        this.cktax = this.datavalue.reduce((sum, item) => {
          let taxAmount1 = parseFloat(item.taxamount1);
          let taxAmount2 = parseFloat(item.taxamount2);
          let taxAmount3 = parseFloat(item.taxamount3);

          return sum + taxAmount1 + taxAmount2 + taxAmount3;
        }, 0);
      },
      (error) => {
        console.log("there was an error sending the query", error);
      }
    );
  }

  //Read Orders
  readOrders() {
    this.readorders
      .rorders(this.cartid)
      .valueChanges.subscribe(({ data }: any) => {
        this.orderdata = data.rorders[0];
        if (this.orderdata.disamount > 0) {
          this.apidisamt = this.orderdata.disamount;
          this.distot = this.cktot - this.apidisamt;
          this.suc = "Coupon Applied successfully!";
          this.err = "";
          this.ccode = this.orderdata.ccode;
        } else {
          this.apidisamt = "";
          this.distot = "";
          this.suc = "";
          this.err = "";
          this.ccode = "";
        }
      });
  }

  onSubmit() {
    const couponCode = this.couponForm.value.couponCode;
    this.verifycoupon.verifycoupon(couponCode).valueChanges.subscribe(
      ({ data }: any) => {
        this.datavaluecc = data.vcoupon;

        if (this.datavaluecc.length > 0) {
          this.disval = this.datavaluecc[0].discount;
          if (this.disval > 0) {
            this.perval = (this.disval / 100) * this.cktot;
            let roundedNumber = parseFloat(this.perval.toFixed(2)).toString();

            this.ccode = this.datavaluecc[0].ccode;
            this.suc = "Applied successfully!";
            this.err = "";

            this.updatecc
              .updateccode(this.cartid, this.ccode, roundedNumber)
              .subscribe(({ data }: any) => {
                this.dataccod = data.uorders[0];
                if (this.dataccod.code == "200") {
                  this.readOrders();
                }
              });
          }
        }

        if (this.datavaluecc.length == 0) {
          this.err = "Invalid coupon code!";
          this.couponForm.reset();
        }
        this.readcart();
      },
      (error) => {
        console.log("there was an error sending the query", error);
      }
    );
  }

  rmcoupon() {
    this.updatecc
      .updateccode(this.cartid, "0", "0")
      .subscribe(({ data }: any) => {
        this.rmc = data.uorders[0];
        console.log(this.rmc);
        if (this.rmc.code == "200") {
          this.suc = "";
          this.apidisamt = "";
          this.distot = "";
          this.couponForm.reset();
        }
      });
  }

  ngOnInit() {
    const storedCart = localStorage.getItem("currentCart");
    if (storedCart) {
      const cartObject = JSON.parse(storedCart);
      this.cartid = cartObject.cartid;
    } else {
      console.log("No cart value found in localStorage.");
    }
    AOS.init();
    this.readcart();
    this.readOrders();
    this.couponForm = this.formBuilder.group({
      couponCode: [""], 
    });
    this.ulevel = 1;
    this.country = localStorage.getItem("country");

    if (this.country == "77") {
      this.ctype = "INR";
    } else {
      this.ctype = "USD";
    }

    this.razorpayService
      .lazyLoadLibrary(environment.razorjs)
      .subscribe();
  }
  RAZORPAY_OPTIONS = {
    key: environment.razorkey,
    amount: "",
    name: environment.razorpname,
    order_id: "",
    description: "Load Wallet",
    image: "https://orcollate.xyz/assets/images/logos/l1.png",
    prefill: {
      name: "orcollate.xyz",
      email: "support@orcollate.xyz",
      contact: "",
      method: "",
    },
    modal: {},
    theme: {
      color: environment.razorcolor,
    },
  };

  public razorPaySuccessHandler(response) {
    const response1 = response;
    this.razorpayResponse = `Razorpay Response`;
    this.showModal = true;
    // document.getElementById('razorpay-response').style.display = 'block';
    this.changeDetectorRef.detectChanges();
    localStorage.removeItem("ftotamt");
    localStorage.removeItem("finvno");
    localStorage.removeItem("payid");

    this.upayment
      .updatepayment(this.cartid, response1.razorpay_payment_id)
      .subscribe(({ data }: any) => {
        this.paydata = data.upayment[0];
        if (this.paydata.code == "200") {
          this.isLoading = false;
          const tottax1 = this.cktax + this.cktot;
          localStorage.setItem("ftotamt", tottax1);
          localStorage.setItem("finvno", this.paydata.invno);
          localStorage.setItem("payid", response1.razorpay_payment_id);
          this.router.navigate(["/paymentsuccess"]);
        }
      });
  }

  public test() {
    document.getElementById("response-modal").style.display = "block";
    this.response = `dummy text`;
  }

  isLoading = false;

  handleClick(): void {
    this.isLoading = true;

    if (this.ulevel == 1 && this.cktot >= 1) {
      let tottax = this.cktax + this.cktot;
      if(tottax < 1 && this.ctype=='INR'){
        tottax = 1;
      }
      if(tottax < 0.5 && this.ctype=='USD'){
        tottax = 0.5;
      }
      const amountInPaise = (tottax * 100).toFixed(0);
      this.RAZORPAY_OPTIONS.amount = amountInPaise.toString();
      this.RAZORPAY_OPTIONS["currency"] = this.ctype;
      this.RAZORPAY_OPTIONS["handler"] = this.razorPaySuccessHandler.bind(this);
      let razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
      razorpay.open();
      // this.upayment.updatepayment(this.cartid).subscribe(({ data }: any) => {
      //   this.paydata = data.upayment[0];
      //   if (this.paydata.code == "200") {
      //     this.isLoading = false;

      //     localStorage.setItem("ftotamt", this.cktot);
      //     localStorage.setItem("finvno", this.paydata.invno);

      //     this.router.navigate(["/paymentsuccess"]); // Redirect using route pat
      //   }
      // });
    } 
    else{
      this.upayment.updatepayment(this.cartid,'orcofree').subscribe(({ data }: any) => {
        this.paydata = data.upayment[0];
        if (this.paydata.code == "200") {
          this.isLoading = false;

          localStorage.setItem("ftotamt", this.cktot);
          localStorage.setItem("finvno", this.paydata.invno);

          this.router.navigate(["/paymentsuccess"]); 
        }
      });
    }

    if (this.ulevel == 3) {
      // this.upaymentpost
      //   .updatepayment(this.cartid)
      //   .subscribe(({ data }: any) => {
      //     this.paydata = data.upaymentpost[0];
      //     if (this.paydata.code == "200") {
      //       this.isLoading = false;
      //       localStorage.setItem("ftotamt", this.cktot);
      //       localStorage.setItem("finvno", this.paydata.invno);
      //       this.router.navigate(["/paymentsuccess"]); // Redirect using route pat
      //     }
      //   });
    }
  }

  //
}
