import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-paysuccess',
  templateUrl: './paysuccess.component.html',
  styleUrls: ['./paysuccess.component.sass']
})
export class PaysuccessComponent {
  ftotamt2: any;
  finvno2: any;
  ulevel: any;
  payid: any;
  country: string;
  ctype: string;
  environment = environment;

  constructor(){

  }

  ngOnInit() {
    this.ftotamt2 = localStorage.getItem("ftotamt");
    this.finvno2 = localStorage.getItem("finvno");
    this.payid = localStorage.getItem("payid");
    this.ulevel=localStorage.getItem("ulevel");
    this.country = localStorage.getItem("country");

    if (this.country == "77") {
      this.ctype = "INR";
    } else {
      this.ctype = "USD";
    }

  }


}
