import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { gql, Apollo } from "apollo-angular";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})


export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;


  transtxt: any;
  datavalue: any[] = [];

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {


    return this.apollo
      .mutate({
        mutation: gql`
          mutation signIn(
            $email_mobile: String!
            $password: String!
          ) {
            signIn(
              email_mobile: $email_mobile
              password: $password
            ) {
              code
              message
              id
              img
              username
              firstname
              lastname
              token
              api_key
            }
          }
        `,
        variables: {
          email_mobile: username,
          password: password,
        },
      })

      .subscribe(
        ({ data }: any) => {

          this.datavalue = data.signIn;
          
         if(this.datavalue[0].code=='200'){  
          this.transtxt = {
            "id": this.datavalue[0].id,
            "img": this.datavalue[0].img,
            "username": this.datavalue[0].username,
            "firstName": this.datavalue[0].firstname,
            "lastName": this.datavalue[0].lastname,
            "token": this.datavalue[0].token,
            "api_key": this.datavalue[0].api_key 
          };
          localStorage.setItem("currentUser", JSON.stringify(this.transtxt));
          this.currentUserSubject.next(this.transtxt);
          if(this.datavalue[0].token=='user-token'){
          this.router.navigate(["/"])
          }
        }
      }
      );

  }

  // login(username: string, password: string) {
  //   return this.http
  //     .post<any>(`${environment.apiUrl}/authenticate`, {
  //       username,
  //       password,
  //     })
  //     .pipe(
  //       map((user) => { 

     
          
  //         localStorage.setItem("currentUser", JSON.stringify(this.transtxt));
  //         console.log(this.transtxt);
  //         this.currentUserSubject.next(this.transtxt);
  //         return user;
  //       })
  //     );
  // }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
