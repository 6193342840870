import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class UpaymentService {
  userdatalist1: any[] = [];

  constructor(private apollo: Apollo) {}

  updatepayment(odid:string, payid:string) {
    return this.apollo.mutate({
      mutation: gql`
        mutation upayment(
          $odid: String!
          $payid: String!
        ) {
          upayment(
            odid: $odid
            payid: $payid
           ) {
            code
            message
            invno
          }
        }
      `,
      variables: {
        odid: odid,
        payid: payid,
        
      },
    });
  }
}


