// src/app/config/website-urls.ts
export const WebsiteUrls = {
  production: {
    list: "https://list.orcollate.xyz",
    shop: "https://shop.orcollate.xyz",
    home: "https://orcollate.xyz",
  },
  development: {
    list: "https://list.orcollate.xyz/",
    shop: "https://shop.orcollate.xyz/",
    home: "https://orcollate.xyz/",
  },
};
