

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Cart } from "../core/models/cart";
import { environment } from "src/environments/environment";
import { gql, Apollo } from "apollo-angular";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})


export class CartsesService {
  private currentUserCart: BehaviorSubject<Cart>;
  public currentCart: Observable<Cart>;


  transtxt: any;
  datavalue: any[] = [];

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private router: Router,
  ) {
    this.currentUserCart = new BehaviorSubject<Cart>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentCart = this.currentUserCart.asObservable();
  }

  public get currentUserValue(): Cart {
    return this.currentUserCart.value;
  }

  cartses(cartid: string) {
    
    this.transtxt = {
      "cartid": cartid,
     }; 
    localStorage.setItem("currentCart", JSON.stringify(this.transtxt));
    this.currentUserCart.next(this.transtxt);
    if(cartid!==''){
    this.router.navigate(["/checkout"]);
    }
  }

 

  cartclear() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentCart");
    this.currentUserCart.next(null);
    return of({ success: false });
  }
}


