import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class VerifycouponService {
  constructor(private apollo: Apollo) {}

  verifycoupon(cid: string) {
    return this.apollo.watchQuery({
      query: gql`
        query vcoupon($cid: String!) {
          vcoupon(cid: $cid) {
            id
            cname
            ccode
            cdesc
            currency
            dtype
            discount
          }
        }
      `,
      fetchPolicy: "network-only",
      variables: {
        cid: cid,
      },
    });
  }
}
