import { Component } from "@angular/core";
import { environment } from '../../../environments/environment';

@Component({
  selector: "app-home-footer",
  templateUrl: "./home-footer.component.html",
  styleUrls: ["./home-footer.component.scss"],
})
export class HomeFooterComponent {
  environment = environment;
}
