



import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { User_Lists } from '../graphql/graphql.queries';

@Injectable({
  providedIn: 'root'
})
export class RpricingService {

  constructor(private apollo: Apollo) { }
  

  rpricing(api_key: string,appid: string) {
    return this.apollo.watchQuery({
      query: gql`
      query rpricing1($appid: String!) {
        rpricing1(appid: $appid) {
          id
          appid
          pname
          pdesc
          pbutton
          ptype
          pcount
          fcount
          country
          omp
          oyp
        }
      }
    `,
    fetchPolicy: 'network-only',
      variables: {
        appid: appid
      },
    })
  }



}

