const orname = 'orcollate.xyz';
const homeurl = 'orcollate.xyz/'

export const environment = {
  production: true,
  apiUrl: 'https://list.' + orname + '/api/v2',
  rUrl: 'https://' + orname + '/',
  homeurl: 'https://' + homeurl,


  //api.config.ts

  apigbe: "https://ga." + orname + "/api/v2",
  apilbe: "https://list." + orname + "/api/v2",
  apisbe: "https://shop." + orname + "/api/v2",
  apinbe: "https://newsletter." + orname + "/api",
  apigbe1: "https://ga." + orname + "/api/v1",
  apilbe1: "https://list." + orname + "/api/v1",
  apisbe1: "https://shop." + orname + "/api/v1",
  apinbe1: "https://newsletter." + orname + "/api",

  //signin
  cookie_url: '.orcollate.xyz',

  //common-urls.ts
  listhome:"https://list." + orname,
  list: "https://list." + orname,
  shop: "https://shop." + orname,
  home: "https://" + orname,
  razorjs : "https://checkout.razorpay.com/v1/checkout.js",
  razorkey : "rzp_test_3KnpTH8F97m44V",
  razorcolor: "#0096C5",
  razorpname: "Orcollate"

};
