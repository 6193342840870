// src/app/my-service.service.ts
import { Injectable, isDevMode } from "@angular/core";
import { WebsiteUrls } from "../config/common-urls";

@Injectable({
  providedIn: "root",
})
export class Url {
  urls = isDevMode() ? WebsiteUrls.development : WebsiteUrls.production;

  shopurl = this.urls.shop;
  listurl = this.urls.list;
  homeurl = this.urls.home;
}
