import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { gql } from "apollo-angular";

@Injectable({
  providedIn: "root",
})
export class AddCartService {
  userdatalist1: any[] = []; 

  constructor(private apollo: Apollo) {}

  addcart(plans: any[]) { 
    return this.apollo.mutate({
      mutation: gql`
        mutation cart($plans: [PlanInput!]!) {
          cart(plans: $plans, discount:"50") {
            cartid
          }
        }
      `,
      variables: {
        plans: plans,
      },
    });
  }
}




