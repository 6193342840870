import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { User_Lists } from "../graphql/graphql.queries";

@Injectable({
  providedIn: "root",
})
export class ReadcartService {
  constructor(private apollo: Apollo) {}

  readcart(odid: string) {
    console.log(odid);
    return this.apollo.watchQuery({
      query: gql`
        query rcart($odid: String!) {
          rcart(odid: $odid) {
            id
            appid
            userid
            plantype
            qty
            price
            duration
            discount
            countryid
            plantype
            taxtype1
            taxrate1
            taxamount1
            taxtype2
            taxrate2
            taxamount2
            taxtype3
            taxrate3
            taxamount3
            totamount
            appdata {
              appname
              appimg
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables: {
        odid: odid,
      },
    });
  }
}
