import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import AOS from "aos";
import { FormControl } from "@angular/forms";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { ReadappService } from "../services/readapp.service";
import { RpricingService } from "../services/rpricing.service";
import { RpricingnoService } from "../services/rpricingno.service";
import { AddCartService } from "../services/add-cart.service";
import { CartsesService } from "../services/cartses.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as jwtDecode from "jwt-decode";
import { Location } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { ThemePalette } from "@angular/material/core";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  datavalue: any;
  datavalue1: any[] = [];
  appcall: string;
  api_key: string;
  selectedParentOption: any;
  selectedChildOption: any;
  // selectedPlanId: any;
  selectedPlanId: number[] = [];
  selectedind: any;
  appid: any;
  appPlans: string[] = [];
  price: any;
  planPrices: any = {};
  qty: any;
  ptype: any;
  selectedindex: number[] = [];
  selectedprice: number[] = [];
  duration: number;
  param1: string;
  param2: string;
  color: ThemePalette = "accent";
  checked = false;
  disabled = false;
  isHighlyRecommendedDisplayed: boolean = false;
  isRecommendedApplicationsDisplayed: boolean = false;
  isRecommendedBusistreamDisplayed: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rapp: ReadappService,
    private rpricing: RpricingService,
    private rpricingno: RpricingnoService,
    private addcart: AddCartService,
    private cartsesv: CartsesService,
    private http: HttpClient,
    private location: Location,
    private cookieService: CookieService
  ) {
    super();
    this.myNumberValue = new Array(30).fill(1);
    // Initialize the array with length 10 and set all elements to 1
    this.duration = 1;
  }
  
 readapp() {
    this.country = localStorage.getItem("country");
    console.log(this.country);
    this.rapp
      .readapplication(this.country, this.param1, "1")
      .valueChanges.subscribe(
        ({ data }: any) => {
          this.datavalue = data.rapp;
        },
        (error) => {
          console.log("there was an error sending the query", error);
        }
      );
  }
  //increment and decrement number field
  // Increment the value for the specified index
  increment(index: number) {
    this.freecount[index]++;
    this.onNumberChange(this.freecount[index], index);

    //+this.datavalue[index]?.appplan[0].count++;
  }
  // Decrement the value for the specified index
  decrement(index: number) {
    if (this.freecount[index] > 1) {
      this.freecount[index]--;
      this.onNumberChange(this.freecount[index], index);
    }
  }
  defaultNumberValue = 0;
  slideToggleValue: boolean = false;
  // Other component code
  handleToggleChange(event: any) {
    this.slideToggleValue = event.target.checked;

    if (this.slideToggleValue) {
      this.duration = 2;
    } else {
      this.duration = 1;
    }

    this.onPlanChange(this.selectedPlanId[this.selectedind], this.selectedind);
    for (let i = 0; i < this.selectedindex.length; i++) {
      this.onPlanChange(
        this.selectedPlanId[this.selectedindex[i]],
        this.selectedindex[i]
      );
    }
  }
  cartses: any;
  selectedPlans: number[] = [];
  selectedQty: string[] = [];
  plantype: number[] = [];
  ests: number[] = [];
  freecount: number[] = [];
  tokenval: number[] = [];
  uid: string;
  uname: string;
  country: string;
  state: string;
  ctype: string;

  cartdata: {
    appid: string;
    plantype: any;
    qty: number;
    price: number;
    duration: number;
    userid: string;
    discount: number;
  }[] = [];
  onPlanChange(planId: number, index: number) {
    this.selectedind = index;
    this.selectedPlanId[index] = planId;
    if (planId) {
      this.rpricingno
        .rpricingno("", this.selectedPlanId[index].toString())
        .valueChanges.subscribe(
          ({ data }: any) => {
            this.datavalue1 = data.rpricingno;

            if (this.slideToggleValue == true) {
              this.qty = this.myNumberValue[index];
              this.price = this.datavalue1[0].oyp;
              this.plantype[index] = this.datavalue1[0].ptype;
              this.ests[index] = this.datavalue1[0].ests;
              this.freecount[index] = this.datavalue1[0].count;
              if (this.plantype[index] == 3) {
                this.selectedPlans[index] = this.datavalue1[0].oyp * 1;
              } else {
                this.selectedPlans[index] = this.datavalue1[0].oyp * this.qty;
              }
            } else {
              this.qty = this.myNumberValue[index];
              this.price = this.datavalue1[0].omp;
              this.plantype[index] = this.datavalue1[0].ptype;
              this.ests[index] = this.datavalue1[0].ests;
              console.log(this.ests[index]);
              this.freecount[index] = this.datavalue1[0].count;
              this.selectedindex.push(index);
              if (this.plantype[index] == 3) {
                this.selectedPlans[index] = this.datavalue1[0].omp * 1;
              } else {
                this.selectedPlans[index] = this.datavalue1[0].omp * this.qty;
              }
            }
            this.selectedprice.push(this.selectedPlans[index]);
            this.cartdata.push({
              appid: this.datavalue[index].id,
              plantype: this.plantype[index],
              qty: Number(this.myNumberValue[index]),
              price: this.price,
              duration: this.duration,
              userid: localStorage.getItem("luid"),
              discount: 0,
            });
            const uniqueArray = this.cartdata
              .reverse()
              .filter((item, index, self) => {
                return (
                  index === self.findIndex((obj) => obj.appid === item.appid)
                );
              })
              .reverse();
            this.cartdata = uniqueArray; 
          },
          (error) => {
            console.log("there was an error sending the query", error);
          }
        );
    }
  }
  calculateOverallTotal(): number {
    let overallTotal = 0;
    for (const value of this.selectedPlans) {
      if (typeof value === "number") {
        overallTotal += value;
      }
    }
    return overallTotal;
  }

  //QTY change
  myNumberValue: number[] = [];
  onNumberChange(value: number, index: number) {
    this.myNumberValue[index] = value;
    this.selectedPlans[index] = value * this.price;
    this.selectedprice.push(this.selectedPlans[index]);
    this.cartdata.push({
      appid: this.datavalue[index].id,
      plantype: this.plantype[index],
      qty: Number(this.myNumberValue[index]),
      price: this.price,
      duration: this.duration,
      userid: localStorage.getItem("luid"),
      discount: 0,
    });
    const uniqueArray = this.cartdata
      .reverse()
      .filter((item, index, self) => {
        return index === self.findIndex((obj) => obj.appid === item.appid);
      })
      .reverse();
    this.cartdata = uniqueArray; // Assign back to the original variable
  }
  goToCheckout() {
    //let filteredData = this.cartdata.filter(item => item.qty !== 0);

    this.addcart.addcart(this.cartdata).subscribe(
      ({ data }: any) => {
        this.datavalue = data.cart;

        if (this.datavalue && this.datavalue.length > 0) {
          const cartid = this.datavalue[0]?.cartid;
          this.cartsesv.cartses(cartid);
        }
      },
      (error) => {
        console.log("There was an error sending the query", error);
      }
    );
  }

  ngOnInit() {
    AOS.init();
    this.route.paramMap.subscribe((params) => {
      this.param1 = params.get("param1");
      this.param2 = params.get("param2");
    });
    this.readapp();

    this.uid = localStorage.getItem("uid");
    this.uname = localStorage.getItem("uname");
    this.country = localStorage.getItem("country");
    this.state = localStorage.getItem("state");

    if (this.country == "77") {
      this.ctype = "INR";
    } else {
      this.ctype = "USD";
    }

    this.route.paramMap.subscribe((params) => {
      const param2 = params.get("param2");
      this.onPlanChange(parseInt(param2), 0);
    });
  }
  //
}
