import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "../service/auth.service";

@Injectable({
  providedIn: "root",      
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue.token=='user-token') {
      return true;
    }
    this.router.navigate(["/signin"]);
    return false;
  }
}
