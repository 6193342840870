<header class="header navbar-area sticky">
  <div class="container dmenu">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-12">
        <div class="nav-inner">
          <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand-logo mh" [href]="environment.rUrl">
              <img
                src="assets/images/l1.png"
                alt="Logo"
                style="margin-left: -50px"
              />
            </a>
            <div
              class="collapse navbar-collapse sub-menu-bar"
              id="navbarSupportedContent"
            >
              <ul
                id="nav"
                class="navbar-nav ms-auto mh"
                style="margin-left: 200px !important"
              >
                <li class="nav-item">
                  <a
                    [href]="environment.rUrl + 'applications'"
                    aria-label="Toggle navigation"
                    style="margin-left: 140px"
                    >Applications</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [href]="environment.rUrl + 'busistream'"
                    aria-label="Toggle navigation"
                    >Busistream</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="dd-menu collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#submenu-1-2"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Company
                  </a>
                  <i class="arrow down"></i>
                  <ul
                    class="sub-menu collapse"
                    id="submenu-1-2"
                    style="width: 256px"
                  >
                    <li class="nav-item" style="height: 24px">
                      <a
                        mat-menu-item
                        [href]="environment.rUrl + 'about-us'"
                        routerLinkActive="active"
                        >About Us</a
                      >
                    </li>
                    <li class="nav-item" style="width: 200px; height: 24px">
                      <a
                        mat-menu-item
                        [href]="environment.rUrl + 'business-ideas'"
                        routerLinkActive="active"
                        >Business Ideas</a
                      >
                    </li>
                    <li class="nav-item" style="width: 200px; height: 24px">
                      <a
                        mat-menu-item
                        [href]="environment.rUrl + 'contact-us'"
                        routerLinkActive="active"
                        >Contact Us</a
                      >
                    </li>
                    <li class="nav-item" style="width: 200px; height: 24px">
                      <a
                        mat-menu-item
                        [href]="environment.rUrl + 'partners'"
                        routerLinkActive="active"
                        >Partner With Us</a
                      >
                    </li>
                    <li class="nav-item" style="height: 24px">
                      <a
                        mat-menu-item
                        [href]="environment.rUrl + 'support'"
                        routerLinkActive="active"
                        >Support</a
                      >
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <img
                    style="height: 28px; margin-left: 168px"
                    [src]="environment.rUrl + '/assets/images/lang.png'"
                  />
                  <span>EN</span>
                </li>

                <li class="nav-item" *ngIf="session">
                  <a
                    class="dd-menu collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#submenu-1-2"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    >{{ this.user_name }}</a
                  >
                  <i class="arrow down"></i>
                  <ul
                    class="sub-menu collapse"
                    id="submenu-1-2"
                    style="width: 256px"
                  >
                    <li class="nav-item">
                      <a
                        class="dropdown-item"  [href]="environment.listhome + '/dashboard/dashboard2'">Admin Privileges</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="dropdown-item" [href]="environment.listhome + '/myaccount'"
                        >My Account</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="dropdown-item" [href]="environment.listhome + '/mybills'">My Bills</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="dropdown-item" [href]="environment.listhome + '/myapi'">My API</a>
                    </li>
                    <li class="nav-item">
                      <a class="dropdown-item" [href]="environment.listhome + '/changepw'">Change Password</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="dropdown-item" [href]="environment.listhome + '/logout'">Logout</a>
                    </li>
                  </ul>
                </li>

                <li class="nav-item">
                  <div class="button" *ngIf="user_name == null">
                    <a [href]="environment.listhome + '/authentication/signin'" class="signIn btn"
                      >Sign In</a
                    >
                    <a [href]="environment.listhome + '/authentication/signup'"
                      class="signUp btn"
                      style="margin-left: 14px"
                      >Sign Up</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <mat-toolbar class="full-width-toolbar mmenu">
    <span
      ><a class="navbar-brand-logo mh" [href]="environment.rUrl">
        <img src="assets/images/l1.png" style="width: 160px" alt="Logo" /> </a
    ></span>

    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="toggleMenu()">
      <mat-icon>{{ isMenuOpen ? "menu" : "menu" }}</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <a
          class="matm"
          [href]="environment.rUrl + 'applications'"
          aria-label="Toggle navigation"
          >Applications</a
        >
      </button>
      <button mat-menu-item>
        <a
          class="matm"
          [href]="environment.rUrl + 'busistream'"
          aria-label="Toggle navigation"
          >Busistream</a
        >
      </button>
      <mat-menu #submenu1="matMenu" class="matm">
        <button mat-menu-item>
          <a
            class="matm"
            mat-menu-item
            [href]="environment.rUrl + 'about-us'"
            routerLinkActive="active"
            >About Us</a
          >
        </button>
        <button mat-menu-item>
          <a
            class="matm"
            mat-menu-item
            [href]="environment.rUrl + 'business-ideas'"
            routerLinkActive="active"
            >Business Ideas</a
          >
        </button>
        <button mat-menu-item>
          <a
            class="matm"
            mat-menu-item
            [href]="environment.rUrl + 'contact-us'"
            routerLinkActive="active"
            >Contact Us</a
          >
        </button>
        <button mat-menu-item>
          <a
            class="matm"
            mat-menu-item
            [href]="environment.rUrl + 'partners'"
            routerLinkActive="active"
            >Partner With Us</a
          >
        </button>
        <button mat-menu-item>
          <a
            class="matm"
            mat-menu-item
            [href]="environment.rUrl + 'support'"
            routerLinkActive="active"
            >Support</a
          >
        </button>
      </mat-menu>
      <button mat-menu-item [matMenuTriggerFor]="submenu1">Company</button>

      <mat-menu #submenu2="matMenu" class="matm">
        <button mat-menu-item>
          <a class="dropdown-item" [href]="environment.listhome + '/dashboard/dashboard2'">Admin Privileges</a
          >
        </button>
        <button mat-menu-item>
          <a class="dropdown-item" [href]="environment.listhome + '/myaccount'">My Account</a>
        </button>
        <button mat-menu-item>
          <a class="dropdown-item" [href]="environment.listhome + '/mybills'">My Bills</a>
        </button>
        <button mat-menu-item>
          <a class="dropdown-item" [href]="environment.listhome + '/myapi'">My API</a>
        </button>
        <button mat-menu-item>
          <a class="dropdown-item" [href]="environment.listhome + '/changepw'">Change Password</a>
        </button>
        <button mat-menu-item>
          <a class="dropdown-item" [href]="environment.listhome + '/logout'">Logout</a>
        </button>
      </mat-menu>

      <button mat-menu-item [matMenuTriggerFor]="submenu2" *ngIf="session">
        {{ this.user_name }}
      </button>

  

      <button mat-menu-item *ngIf="user_name == null">
        <a
          class="matm"
          [href]="environment.listhome + 'signin'"
          aria-label="Toggle navigation"
          >Sign In</a
        >
      </button>
      <button mat-menu-item *ngIf="user_name == null">
        <a
          class="matm"
          [href]="environment.listhome + 'signup'"
          aria-label="Toggle navigation"
          >Sign Up</a
        >
      </button>

      <!-- Add more menu items or submenus as needed -->
    </mat-menu>
  </mat-toolbar>
</header>
