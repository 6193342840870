import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { CartComponent } from "./cart/cart.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import { PaysuccessComponent } from "./paysuccess/paysuccess.component";
import { AllinoneComponent } from "./allinone/allinone.component";

const routes: Routes = [
  { path: "", component: CartComponent },
  { path: "cart/:param1/:param2", component: CartComponent },
  { path: "cart", component: CartComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "paymentsuccess", component: PaysuccessComponent },
  { path: "allinone", component: AllinoneComponent },
  { path: "allinone/:param1/:param2", component: AllinoneComponent },

  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "signin",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
      {
        path: "tables",
        loadChildren: () =>
          import("./tables/tables.module").then((m) => m.TablesModule),
      },
    ],
  },

  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
