import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AuthService } from '../core/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RordersService {

  constructor(private apollo: Apollo,private authService:AuthService) { }
  

  rorders(odid:string) { 
    return this.apollo.watchQuery({
      query: gql`
      query rorders($odid: String!) {
        rorders(odid: $odid) {
          id
          orderid
          userid
          paysts
          invno
          vname
          vpayid
          vodid
          subid
          expsts
          invsts
          cname
          invadd
          taxno
          ccode
          disamount
          orderdate
          sdate
          edate
        }
      }
    `,
    fetchPolicy: 'network-only',
      variables: {
        odid: odid,
      },
    })
  }



}


