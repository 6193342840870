import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { User_Lists } from '../graphql/graphql.queries';

@Injectable({
  providedIn: 'root'
})
export class ReadappService {

  constructor(private apollo: Apollo) { }
  

  readapplication(country:string, appid: string,cat:string) {
    return this.apollo.watchQuery({
      query: gql`
      query rapp($country: String, $appid: String, $cat: String!) {
        rapp(country:$country, appid: $appid, cat: $cat) {
          id
          catname
          scatname
          appname
          appurl
          applogo
          appplan {
            id
            planname
            ests
            omp
            oyp
            count
          }
        }
      }
    `,
    fetchPolicy: 'network-only',
      variables: {
        country: country,
        appid: appid,
        cat: cat
      },
    })
  }



}

